<template>
  <div>
    <LoadingOverlay v-if="isFetchingGenericFilter" />
    <GenericFilter
      :basicFilterlength="getFilterSchema.basicFilterLength"
      :filterSchema="getFilterSchema.schema"
      :selectedFilters="selectedFilters"
      :filterDependencyObj="filterDependencyObj"
      :isFetchingFilter="isFetchingGenericFilter"
      :callback="updateRouterURL"
      :searchCallback="searchCallback"
      :filterAction="filterAction"
    />
    <div class="job-board">
      <div v-if="isMobile">
        <advertisement :allign="'horizontal'"></advertisement>
      </div>

      <div class="d-flex flex-row">
        <div
          class="d-flex flex-row w-100"
          v-if="
            !isFetchingGenericFilter &&
            !isJobBoardFetching &&
            !getJobBoard.length
          "
        >
          <div class="d-flex flex-column" style="margin-right: 20%">
            <h5 v-if="getJobCount" class="count">
              Total Jobs: {{ getJobCount }}
            </h5>
            <h5 class="count">Search Results: {{ getFilterCount }}</h5>
          </div>

          <div class="d-flex flex-column mt-5">
            <h4>
              No jobs matched the selected criteria
              <span v-if="AddJobRoles.includes(currentUserRole)"
                >, Please add a Job</span
              >
            </h4>
            <CButton
              v-if="AddJobRoles.includes(currentUserRole)"
              @click="navigateToAddJob()"
              class="btn-primary"
              title="Add Job"
              >Add Job</CButton
            >
          </div>
        </div>

        <div
          id="job-board-body"
          class="job-board-list col-md-4"
          v-if="
            !(
              !isFetchingGenericFilter &&
              !isJobBoardFetching &&
              !getJobBoard.length
            )
          "
        >
          <div>
            <h6 v-if="getJobCount" class="count">
              Total Jobs: {{ getJobCount }}<span class="pl-3"></span> Search
              Results: {{ getFilterCount }}
            </h6>
          </div>
          <job-card
            :key="index"
            :index="index"
            v-for="(job, index) in getJobBoard"
            :item="job"
            :class="
              !isMobile &&
              getSelectedJobBoard &&
              getSelectedJobBoard.job_id == job.job_id
                ? 'highlight'
                : 'disabled'
            "
            @click.native="jobCardSelectedCallback(job)"
          ></job-card>
          <div v-if="noMoreDataFromJobBoard" class="no-more-data">
            <p v-if="getJobBoard.length > 0" class="btn-primary">No More Data!</p>
          </div>
          <div
            v-if="!noMoreDataFromJobBoard"
            class="d-flex justify-content-around align-items-center has-loading-overlay"
            style="height: 200px"
          >
            <LoadingOverlay v-if="isJobBoardFetching" />
          </div>
        </div>
        <div
          class="job-board-detail col-md-6"
          v-if="getJobBoard.length > 0 || isJobBoardFetching"
        >
          <CModal
            id="applyjobModal"
            title="Apply Job"
            color="primary"
            centered:false
            @update:show="onShow"
            :show.sync="successModal"
            >{{ this.modalMessage }}</CModal
          >
          <job-detail :getSelectedJobBoard="getSelectedJobBoard"> </job-detail>
        </div>
        <div
          v-if="isMobile != true"
          class="col-lg-2"
          style="padding-right: 0px"
        >
          <advertisement :allign="'verticle'"></advertisement>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jobCard from "@/pages/DemoDOH/jobCard";
import jobDetail from "@/pages/DemoDOH/JobBoardDetail/jobDetail";
import { mapGetters, mapActions } from "vuex";
import TextInput from "@/components/reusable/Fields/TextInput";
import router from "@/router/index";
import { parseJwt } from "@/helpers/helper";
import { getScope, Role } from "@/helpers/helper";
import GenericFilter from "@/components/reusable/GenericFilterAndSearch/index";
import advertisement from "@/components/reusable/Advertise.vue";

export default {
  components: {
    "job-card": jobCard,
    "job-detail": jobDetail,
    TextInput,
    GenericFilter,
    advertisement,
  },
  data() {
    return {
      searchTerm: "",
      isMobile: false,
      successModal: false,
      modalMessage: "",
      page: "",
      currentUserRole: getScope(),
      Role,
      urlFullPath: "",
      jobId: "",
      AddJobRoles: [
        Role.systemAdmin,
        Role.customerAdmin,
        Role.customerRecruiter,
        Role.systemCandidate,
        Role.systemRecruiter,
      ],
    };
  },
  mounted() {
    this.urlFullPath = this.$route.fullPath;
    this.initAllFilters().then(async () => {
      let initialEmptySelectedCheck = await this.updateSelectedFilterFromFilterCode(
        this.$router.currentRoute.query
      );
      let queryUrl = this.getQueryResult.queryUrl;
      if (queryUrl) await this.jobCount({ query: "" });
      //Todo working on the two api calls issue
      this.jobBoardAction({
        page: this.page,
        query: this.getQueryResult.queryUrl,
      });
    });

    let isMobile = window.matchMedia("only screen and (max-width: 760px)")
      .matches;
    this.isMobile = isMobile;
    let ele;
    if (!isMobile) {
      ele = document.getElementById("job-board-body");
    } else {
      if (this.urlFullPath.includes("/public")) {
        ele = document.getElementById("c-body-app");
      } else {
        ele = document.getElementById("c-body-app");
      }
    }
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
  beforeDestroy() {
    let ele;
    if (!this.isMobile) {
      ele = document.getElementById("job-board-body");
    } else {
      if (this.urlFullPath.includes("/public")) {
        ele = document.getElementById("app");
      } else {
        ele = document.getElementById("c-body-app");
      }
    }
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },

  created() {
    if (this.getRoleScopes.length) {
      if (
        [
          "system_admin",
          "customer_candidate",
          "system_recruiter",
          "system_candidate",
        ].includes(this.getRoleScopes[0])
      ) {
        this.page = "publicJobBoard";
      } else {
        this.page = "jobBoard";
      }
    } else {
      this.page = "publicJobBoard";
    }
    this.setCurrentPageSchema({ schema: "jobBoardSchema" });
  },

  updated() {
    let { job_id } = this.$route.query;
    const jobId = parseInt(job_id) | 0;
    if (jobId !== this.jobId) {
      this.selectedJobBoard(parseInt(jobId));
    }
    this.jobId = jobId;
  },
  methods: {
    ...mapActions([
      "jobBoardAction",
      "selectedJobBoard",
      "applyJob",
      "showToast",
      "setCurrentPageSchema",
      "initAllFilters",
      "updateSelectedFilter",
      "clearSearchFilterWithUpdateSearch",
      "updateSelectedFilterFromFilterCode",
      "clearAllSelectedFilters",
      "jobCount",
    ]),
    searchCallback(payload) {
      payload["type"] = "searchTerm";
      this.updateRouterURL(payload);
    },
    jobCardSelectedCallback({ job_id }) {
      setTimeout(() => {
        const e = document.getElementsByClassName("loader");
        for (let i in e) {
          if (e.hasOwnProperty(i)) {
            console.log(e);
            e[i].style.borderTop = " 1rem solid #00626A";
            e[i].style.borderBottom = "1rem solid #00626A";
          }
        }
      }, 10);
      if (this.isMobile) {
          console.log(this.getRoleScopes)
        if (this.getRoleScopes.length) {
          if (
            this.getRoleScopes[0] === "system_admin" ||
            this.getRoleScopes[0] === "system_recruiter"
          ) {
            this.$router.push({ path: `/public/job-board-detail/${job_id}` });
          } else if (
            this.getRoleScopes[0] === "customer_admin" ||
            this.getRoleScopes[0] === "customer_recruiter"
          ) {
            this.$router.push({ path: `/job-board-detail/${job_id}` });
          } else if ( ["customer_candidate", "system_candidate"].includes(
              this.getRoleScopes[0]
            )) {
            this.$router.push({ path: `/public/job-board-detail/${job_id}` });
          } else {
            this.$router.push({ path: `/job-board-detail/${job_id}` });
          }
        } else {
          this.$router.push({ path: `/doh/public/job-board-detail/${job_id}` });
        }
      } else {
        let path = `${this.$route.path}?job_id=${job_id}&${this.getQueryResult.routeUrl}`;
        this.$router
          .push({
            path,
          })
          .catch(() => {});
        this.selectedJobBoard(parseInt(this.$route.query.job_id));
      }
    },
    onBodyScroll(e) {
      if (this.noMoreDataFromJobBoard || this.isJobBoardFetching) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
        e.target.scrollHeight - 1
      ) {
        this.jobBoardAction({
          pagination: true,
          page: this.page,
          query: this.getQueryResult.queryUrl,
        });
      }
    },
    handleSearchSubmit() {
      this.jobBoardAction(this.searchTerm);
    },
    handleSearchInput(name, value) {
      this.searchTerm = value;
    },
    clearSearch() {
      this.searchTerm = "";
      this.jobBoardAction();
    },
    onApplyJob() {
      this.successModal = true;
      if (this.getRoleScopes.length) {
        this.modalMessage = "You have selected the Job to Apply";
      } else {
        this.modalMessage = "Please Login/Register to apply for the jobs";
      }
    },
    navigateToAddJob() {
      router.push("/add-job?step=0");
    },
    onShow(e, value) {},
    updateRouterURL(payload) {
      let appendAction = this.updateSelectedFilter(payload);
      Promise.all([appendAction]).then((res) => {
        let path = `${this.$route.path}?${this.getQueryResult.routeUrl}`;
        this.$router.push({ path });
      });
    },
    filterAction() {
      this.initAllFilters().then(async () => {
        await this.clearAllSelectedFilters();
        await this.updateSelectedFilterFromFilterCode(
          this.$router.currentRoute.query
        );
        this.jobBoardAction({
          page: this.page,
          query: this.getQueryResult.queryUrl,
        });
      });
    },
  },
  computed: {
    customMarginBasedOnPage() {
      if (
        this.getRoleScopes.length &&
        this.$route.path !== "/public/job-board"
      ) {
        return 10;
      }
      return 70;
    },
    ...mapGetters([
      "getJobBoard",
      "getSelectedJobBoard",
      "noMoreDataFromJobBoard",
      "isJobBoardFetching",
      "getJobCount",
      "getFilterCount",
      "getRoleScopes",
      "jobBoardSchema",
      "jobBoardSchemaForCandidate",
      "selectedFilters",
      "filterDependencyObj",
      "isFetchingGenericFilter",
      "getQueryResult",
      "isSelectedFilterUpdated",
    ]),
    getFilterSchema() {
      return this.currentUserRole
        ? { schema: this.jobBoardSchema, basicFilterLength: 4 }
        : { schema: this.jobBoardSchemaForCandidate, basicFilterLength: 5 };
    },
  },
};
</script>
<style lang="scss" scoped>
.count {
  color: #00626A;
}
</style>
