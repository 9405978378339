<template>
  <div class="Demo-DOH">
    <header
      class="bg-clr sticky-top flex-d c-header c-header-light c-header-fixed c-header-with-subheader"
    >
      <div>
        <div class="col-12 logo">
          <div>
            <a
              href="https://www.doh.gov.ae/covid-19/Health-Workforce-Management-System"
              target="_blank"
              class="p-1 d-inline-block"
              ><img src="/img/white-logo-doh.svg" class="mw-100 h-auto"
            /></a>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column logo-title p-2 linespcad title">
        <div>
          <span class="fs-12">Abu</span>
          <span class="fs-12"> Dhabi's </span>
          <span class="fs-12"> Health </span>
          <span class="fs-12"> Workforce </span>
          <span class="fs-12"> Careers </span>
          <span class="fs-12"> Portal </span>
        </div>
      </div>
      <div class="d-flex align-items-center right-action">
        <ul class="c-header-nav">
          <li class="c-header-nav-item dropdown mx-2 d-none d-md-block">
            <CDropdown inNav class="c-header-nav-items">
              <template #toggler>
                <a class="custom-nav-link"
                  ><span class="header-nav p-1 fs-12">About Abu Dhabi</span></a
                >
              </template>
              <CDropdownItem
                href="https://www.doh.gov.ae/en"
                target="_blank"
                class="fs-12"
              >
                Living & working in Abu Dhabi
              </CDropdownItem>
              <CDropdownItem
                href="https://www.doh.gov.ae/en"
                target="_blank"
                class="fs-12"
              >
                Abu Dhabi Health Workforce Plan
              </CDropdownItem>
            </CDropdown>
          </li>
          <li class="c-header-nav-item dropdown mx-2 d-none d-md-block">
            <CDropdown inNav class="c-header-nav-items">
              <template #toggler>
                <a class="custom-nav-link"
                  ><span class="header-nav p-1 fs-12"
                    >Explore Health Careers</span
                  ></a
                >
              </template>
              <CDropdownItem
                href="https://www.doh.gov.ae/en"
                target="_blank"
                class="fs-12"
              >
                Abu Dhabi Hospitals & Health Facilities</CDropdownItem
              >
              <CDropdownItem
                href="https://www.doh.gov.ae/en"
                target="_blank"
                class="fs-12"
              >
                How to Apply for your Medical License
              </CDropdownItem>
            </CDropdown>
          </li>
          <li class="c-header-nav-item dropdown mx-2 d-none d-md-block">
            <CDropdown inNav class="c-header-nav-items">
              <template #toggler>
                <a class="custom-nav-link"
                  ><span class="header-nav p-1 fs-12"
                    >Career Planning & Opportunities</span
                  ></a
                >
              </template>
              <CDropdownItem
                href="https://www.doh.gov.ae/en"
                target="_blank"
                class="fs-12"
              >
                Residency Programs & Available Seats</CDropdownItem
              >
              <CDropdownItem
                href="https://www.doh.gov.ae/en"
                target="_blank"
                class="fs-12"
              >
                Administrative & Fellowship Opportunities</CDropdownItem
              >
              <CDropdownItem
                href="https://www.doh.gov.ae/en"
                target="_blank"
                class="fs-12"
              >
                Other Training Opportunities</CDropdownItem
              >
            </CDropdown>
          </li>
        </ul>
        <a
          href="/doh/pqr-check"
          class="c-header-nav-link text-white hover-decoration-none p-1 fs-12"
          >Eligibility Check</a
        >
      </div>
    </header>
    <div style="margin: 70px 0px; padding-top: 2rem" class="container-fluid">
      <jobBoard />
    </div>
    <footer
      class="page-footer f-bg font-small white text-white c-footer c-footer-fixed"
    >
      <div>
        <div class="ml-1 copy-right">
          © 2011 – 2020 OHR Ltd or its affiliates
        </div>
      </div>
      <div class="ml-auto d-flex flex-column">
        <span class="mr-1"
          ><span class="fw-b"
            ><div class="logo-adjustment">powered by</div>
            TALENT</span
          ><span class="fw-5">FIND™</span></span
        >
      </div>
    </footer>
  </div>
</template>

<script>
import jobBoard from "@/pages/DemoDOH/JobBoard";
export default {
  name: "DemoDOH",
  components: {
    jobBoard,
  },
};
</script>

<style lang="scss" scoped>
.Demo-DOH {
  .c-header.c-header-fixed {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    font-family: "Roboto", sans-serif !important;
  }
  .c-header[class*="bg-"] {
    border-color: rgba(0, 0, 21, 0.1);
  }
  .bg-clr {
    background-color: rgb(10, 94, 103);
  }
  .container-fluid {
    padding-right: 30px;
    padding-left: 30px;
  }
  .hover-decoration-none:hover {
    text-decoration: none;
  }
}
.custom-nav-link {
  span {
    cursor: pointer;
  }
}
.fs-12 {
  font-size: 12.8px;
}
</style>
